import React, {useState} from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import Img from "gatsby-image"
// import { Link } from "gatsby"
import { useImagesPhilosophy } from "../hooks/use-images-philosophy"
import { TextContainer } from './common'
import { SiLinkedin } from 'react-icons/si';




// const bcrypt = require('bcryptjs');
// const salt = '$2a$10$xRjs/hBHhfVisrjTql4xuO'
// const user = 'build_user'
// const hashedUser = bcrypt.hashSync('FederoffLawBuild1120', salt);


const Bg = styled.div`
   width: 100%;
   color: #333;
   overflow: hidden;
   position: relative;


   h1{
       color: #333;
       text-align: center;
       margin-bottom: 2rem;
   }

   p{
       width: 80%;
       margin: auto;
       margin-bottom: 1rem;
       font-size: 1.6rem;
       text-align: left;

   }

   span{
       font-size: 1.4rem;
   }

   @media (max-width: 1000px) {
       h1{
            font-size: 2.4rem;
       }
    }
    
`

const CircleDiv = styled.div`
    border-radius: 50%; 
    width: 20vw;
    height: 20vw;
    background-color: #0099df;
    color: white;
    float: left;
    margin-right: 3vw;
    position: relative;
    margin-top: 0%;

    h1{
        color: white;
        text-align: center;
        font-size: 2vw;
        margin-bottom: 5px;
        margin-top: 0;
        max-width: 18vw;

        @media (max-width: 600px) {
            font-size: 2rem;
            max-width: 32vw;
        }
        @media (max-width: 480px) {
            font-size: 1.6rem;
        }
        @media (max-width: 400px) {
            font-size: 1.4rem;
        }
    }
    p{
        font-size: 1.4vw;
        line-height: 160%;
        @media (max-width: 600px) {
            font-size: 1.3rem;
        }
    }

    @media (max-width: 600px) {
        float: none;
        margin: auto;
        width: 40vw;
        height: 40vw;
        margin-bottom: 4vw;

    }
`


let Images =  {}



const PdsInfo = () =>{
    // const [more, setMore] = useState(false);

    Images = useImagesPhilosophy()

    // const sendRequest = async(evt) =>{
       
    //     // console.log("this is my url: ", `https://feodorofflaw-api.herokuapp.com/api/v1/get_img_file?imgId=33&email=${user}&password=${hashedUser}`)  

    //     // https://feodorofflaw-api.herokuapp.com/api/v1/get_img_file?imgId=33&email=${user}&password=${hashedUser}
    //     // https://feodorofflaw-api.herokuapp.com/api/v1/get_img_file

    //     await fetch(`https://feodorofflaw-api.herokuapp.com/api/v1/get_img_arr`,
    //     {method: "POST", mode: "cors", cache: "no-cache", credentials: "omit", headers: {"Content-Type": "application/json"}, redirect: "follow", referrer: "client", body: JSON.stringify({'email': user, 'password': hashedUser})})
    //     .then(res => res.json())
    //     .then(json => {
    //         console.log("this is my response: ", json)  
    //     })
    //     // await fetch(`https://star-parfum-bucket-unique-name-identifier-33.s3.amazonaws.com/0a4d0bf0-0b5b-415b-bf10-3d91e32ab0d1/IMG_1.jpg`)
    //     // .then(res => res.json())
    //     // .then(json => {
    //     //     console.log("this is my response: ", json)  
    //     // })
    //     // https://star-parfum-bucket-unique-name-identifier-33.s3.amazonaws.com/0a4d0bf0-0b5b-415b-bf10-3d91e32ab0d1/IMG_1.jpg
    // }


    return(
        
            <Bg>
                <div style={{zIndex:'1', position: 'relative'}}>
                    <h1>Per Diem Services</h1>
                    <p>My versatility, commitment to oral advocacy, and dedication to effective legal writing put me in a unique position to address your firm’s per diem needs. Prior to starting Feodoroff Law PLLC, I worked for a high volume full-service New York-Washington D.C immigration law firm, handling the majority of the legal writing in the sphere of: motions to reopen, asylum, removal defense, Board appeal, and federal related matters.</p>
                    <p>Furthermore, I have prepared clients for and made appearances for removal merits hearings and benefits interviews in New York, Newark, Elizabeth, Buffalo, Boston, Philadelphia, Baltimore, Virginia, Orlando, and Los Angeles. I pride myself in thorough file review, detailed witness preparation, and building a detailed record to preserve issues on appeal.</p>
                    <p><i>Further Description Available Here: </i> <a href='https://www.linkedin.com/in/nikolay-nick-feodoroff-aa59b7a5/'><SiLinkedin size={48} style={{marginTop: '1rem', width:'max-content', display:'block', cursor:'pointer', color:'#0273b1'}}/></a></p>


                    <div id='s_philosophy_wrapper_circles_container' css={css`
                        margin-left: 5.5%;
                        @media (max-width: 600px) {
                            margin-left: 0%;                                                            
                        }
                        `}style={{width: "100%", marginTop:'4vw', marginBottom:'4vw', overflow: 'auto'}}>
                        <CircleDiv>
                            <TextContainer style={{top:'50%'}}>
                                <h1>Motions to Reopen</h1>
                            </TextContainer>
                        </CircleDiv>
                        <CircleDiv>
                            <TextContainer style={{top:'50%'}}>
                                <h1>Legal Briefs</h1>
                            </TextContainer>
                        </CircleDiv>
                        <CircleDiv>
                            <TextContainer style={{top:'50%'}}>
                                <h1>Immigration Court Representation</h1>
                            </TextContainer>
                        </CircleDiv>
                        <CircleDiv>
                            <TextContainer style={{top:'50%'}}>
                                <h1>Interview Representation</h1>
                            </TextContainer>
                        </CircleDiv>
                    </div>
                    
                    {/* <button onClick={sendRequest}>Img Test</button> */}
                </div>


                <div style={{position:'absolute', bottom: '-5vw', width: '100%'}}>
                    <div id='pds_bg_shade' css={css`
                    width: 100%;
                    z-index: -1;
                    position: absolute;
                    height: 100%;
                    
                    background: linear-gradient(0deg, rgba(255,255,255, 0) 0%, rgba(255,255,255,1) 90%);
                    @media (max-width: 900px) {
                        background: linear-gradient(0deg, rgba(255,255,255, 0) 0%, rgba(255,255,255,1) 95%);                    
                    }                
                    @media (max-width: 600px) {
                        background: linear-gradient(0deg, rgba(255,255,255, 0) 0%, rgba(255,255,255,1) 99%);
                    }
                    `} style={{zIndex:'-1'}} />

                    <Img id='s_philosophy_bg_img' css={css`
                        z-index: -2;
                        position: absolute;
                        width: 100%;
                        bottom: 0;
                        @media (max-width: 900px) {
                        }
                        @media (max-width: 600px) {
                            width: 280%;
                            left: -63%;
                        }
                        `}
                        fluid={Images['bridge'].childImageSharp.fluid}
                    />  

                </div>  
            </Bg> 
       
    )
}

export default PdsInfo
