import React, {useState} from "react"
import { css } from "@emotion/core"

import Footer from '../components/footer'
import BtnUp from '../components/btn_up'
import NavBar from '../components/nav-bar'
import ContactSec from '../components/contact_section'

import PdsInfo from '../components/pds_info'
import ConsulSign from '../components/consul_sign'
import NlSign from '../components/nl_sign'

import { getShowNL, setShowNL } from "../cookies/nl_sign_cookie"
import { getMenuItem, setMenuItem } from "../cookies/submenu_cookie"
// import scrollTo from 'gatsby-plugin-smoothscroll'
import { navigate } from "gatsby"


// import WhyUsSec from '../components/why_section'
import { Body } from '../components/common'




export default () =>{ 
    const [consultation, setConsultation] = useState(false);
    
    const openConsulSign = (evt) =>{
        setConsultation(true)
    }

    if(!getShowNL || !getShowNL()['show']){
        setShowNL({'show': true})
    }

    const changeService = (evt) =>{
        // console.log('option: ', evt.target.id)
        var menu_temp = getMenuItem()

        if(evt.target.id === 'true'){
            // scrollTo('#is')
            // setMenuItem({'item': evt.target.name})
            menu_temp['item'] = evt.target.name
            setMenuItem(menu_temp)
            navigate('/#is')
            // setService(evt.target.name)
        }
        // else{
        //     // scrollTo('#per_diem')
        //     menu_temp['item2'] = parseInt(evt.target.name) - 1
        //     setMenuItem(menu_temp)
        //     navigate('/#per_diem')
        //     // setPerDiem(parseInt(evt.target.name) - 1)
        // }
    }

    return(
        <Body>
            <NavBar pickService={changeService} iService={0} perDiem={0} abs={true} isSticky={false} consulOpen={openConsulSign}/>
            <NavBar pickService={changeService} iService={0} perDiem={0} isSticky={true} consulOpen={openConsulSign}/>
            <div css={css`
                height: 50px;                                                            
                @media (max-width: 650px) {
                    height: 100px;                                                            
                }
                `}></div>
            <NlSign delay={60} open={false}/>
            <ConsulSign open={consultation} onClose={()=>setConsultation(false)}/>
            <PdsInfo/>
            <ContactSec perdiem={true}/>
            <Footer/>
            <BtnUp/>
        </Body>
    )
}

